/**
 * All application-specific configuration should go here. Where possible, all
 * `import.meta.env` refs should be contained to this file.
 *
 * This includes all 'tuneable' settings.
 *
 * NOTE: in development, changes to env vars will NOT be automatically updated
 * due to compilation caching. Either clear the cache or touch this file to
 * force a recompile.
 */

import { initAxios } from 'adready-api/init';
import http from 'adready-api/api/http';

// App specific config
const config = {
  // Hostname where production app is located
  PROD_HOSTNAME: 'platform.adready.com',

  // AdReady+ URL
  // overriden from init below
  PLUS_URL: 'https://plus.adready.com',

  // Used for error reporting
  SENTRY_DSN: null,

  // Only in *PROD*
  GOOGLE_ANALYTICS_ID: null,

  // Name of the environment, e.g., prod, staging, dev, qa, qa2, qa3
  ENV_NAME: null,

  // When enabled, allows selecting all target types for all products
  DEBUG_TARGETING: import.meta.env.VITE_APP_DEBUG_TARGETING === '1',

  // URL prefix for API endpoint, e.g., https://platform.adready.com
  //
  // *OPTIONAL*: Normally this will be the same server where this .js is served
  // and does not need to be set. For local development against a remote API
  // endpoint, set this to the URL of the env you want to use, e.g.:
  // https://dev.ks.adreadystg.com
  API_URL: import.meta.env.VITE_APP_KICKSTART_WEB_API_URL
    ? import.meta.env.VITE_APP_KICKSTART_WEB_API_URL
    : '',

  PENDO_API_KEY: import.meta.env.VITE_APP_PENDO_API_KEY
    ? import.meta.env.VITE_APP_PENDO_API_KEY
    : '',

  COOKIE_DOMAIN: null,

  async init() {
    // Load some configs dynamically
    //
    // This solves the problem of baking a docker image with a static JS build
    // but still change certain config values dynamically based on the
    // environment on app startup.
    //
    // ALL per-ENV vars *MUST* be loaded from this endpoint!
    return http.AXIOS.get(`/config`)
      .then((res) => {
        const serverConfig = res.data;
        config.PLUS_URL = serverConfig.adReadyPlusURL;
        config.SENTRY_DSN = serverConfig.sentryDSN;
        config.GOOGLE_ANALYTICS_ID = serverConfig.googleAnalyticsID;
        config.ENV_NAME = serverConfig.env;
        config.COOKIE_DOMAIN = serverConfig.params.domain;
        config.FLIP_URL = serverConfig.params.flipUrl;
        config.MNI_URL = serverConfig.mniUrl;
        config.ADREADY_URL = serverConfig.params.apiUrl;

        return config;
      })
      .catch((e) => {
        // if this fails, something critical went wrong.
        // TODO: show a modal 500 error at least
        console.error('Failed to load /config:', e);
      });
  },
  initializePendo() {
    if (!config?.pendoEnabled?.()) {
      console.log('Pendo credentials not found');
      return;
    }
    (function pendoFunction1(apiKey) {
      (function pendoFunction2(p, e, n, d, o) {
        let w;
        let x;
        p[d] = p[d] || {};
        o = p[d];
        o._q = o._q || [];
        const v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
        for (w = 0, x = v.length; w < x; ++w)
          (function pendoFunction3(m) {
            o[m] =
              o[m] ||
              function pendoFunction4(...args) {
                o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(args, 0)));
              };
          })(v[w]);
        const y = e.createElement(n);
        y.async = !0;
        y.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`;
        const z = e.getElementsByTagName(n)[0];
        z.parentNode.insertBefore(y, z);
      })(window, document, 'script', 'pendo');
    })(config.PENDO_API_KEY);
  },
  pendoEnabled() {
    return this.PENDO_API_KEY && this.PENDO_API_KEY !== 'undefined';
  },
};

// No need for an absolute URL here as the API host is *always* the same as the
// UI (static) host.
initAxios(`${config.API_URL}/api`, config);

export default config;
